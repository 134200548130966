import { get, getQueryString, post } from './utils';

export async function getCurrentUserApi() {
  const user = await get(`/account/profile`);
  if (user?.data?.storeId) {
    const stores = await get(`/common/stores`);
    user.data.store = stores?.data?.find((e) => e.id === user.data?.storeId);
  }
  return user;
}

export async function loginApi(data) {
  return post(`/account/authenticate`, data);
}

export async function loginSSOApi(data) {
  return post(`/account/authenticate_sso`, data);
}

export async function forgotPasswordApi(data) {
  return post(`/account/forgot_password`, data);
}

export async function resetPasswordApi(data) {
  return post(`/account/reset_password`, data);
}

export async function changePasswordApi(data) {
  return post(`/account/change_password`, data);
}

export async function resetPasswordByUserIdApi(id) {
  return post(`/account/reset_password_user/${id}`);
}

export async function logoutByUserIdApi(id) {
  return post(`/account/force_logout_user/${id}`);
}

export async function changeStorePINApi(data) {
  return post(`/merchant_terminals/change-retailer-pin`, data);
}

export const exportPermissions = (query, pathExport) => {
  const request = new XMLHttpRequest();
  request.open(
    'GET',
    `${
      process.env.REACT_APP_SERVER_URL
    }/v1/server/permission/${pathExport}?${getQueryString(query)}`,
  );
  request.setRequestHeader(
    'Authorization',
    `Bearer ${localStorage.getItem('sessionToken')}`,
  );
  request.responseType = 'arraybuffer';
  request.onload = () => {
    if (request.status === 200) {
      const disposition = request.getResponseHeader('Content-Disposition');
      const matches = disposition?.substring(
        disposition.indexOf('filename=') + 9,
        disposition.length,
      );
      const filename =
        matches != null && matches !== '' ? matches : 'Permissions.xlsx';
      const blob = new Blob([request.response], {
        type: request.getResponseHeader('content-type'),
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  request.send();
};
