import common from './common.json';
import patronSegments from './patronSegments.json';
import storeSegments from './storeSegments.json';
import adminFees from './adminFees.json';
import reports from './reports.json';
import watchlistRules from './watchlistRules.json';
import reportSandsRewardsSummary from './reportSandsRewardsSummary.json';

export default {
  ...patronSegments,
  ...storeSegments,
  ...adminFees,
  ...common,
  ...reports,
  ...watchlistRules,
  ...reportSandsRewardsSummary,
};
