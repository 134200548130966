import { get } from './utils';

export async function getConfigApi() {
  const res = await Promise.all([get(`/lookup_table`), getStoresApi()]);
  return {
    ...res?.[0],
    stores_full_info: res?.[0]?.stores ?? [],
    stores: res?.[1]?.data || [],
  };
}

export async function getCommonRolesApi() {
  return get(`/common/roles`);
}

export async function getUserAccessTypeApi() {
  return get(`/user_access`);
}

export async function getStoresApi() {
  return get(`/common/stores`);
}

export async function getRetailUserAccessTypeApi() {
  return get(`/user_access/retail`);
}

export async function getTMSUserAccessTypeApi() {
  return get(`/user_access/tms`);
}

export async function getMallApi() {
  return get(`/common/mall`);
}

export async function getStatusCampaignApi() {
  return get(`/common/statuscampaign`);
}

export async function getFunctionApi() {
  return get(`/common/function`);
}

export async function getStoreLevelApi() {
  return get(`/common/storelevel`);
}

export async function getStoreTypeApi() {
  return get(`/common/storetype`);
}

export async function getMachinesApi(params) {
  return get(`/common/machines`, params);
}

export async function getGSTsApi() {
  return get(`/common/gst`);
}
