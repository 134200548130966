import * as Sentry from '@sentry/browser'

export const errorHandler = (error) => {
  const chunkFailedMessage = /Loading chunk [\d] + failed/;

  if (chunkFailedMessage.test(error.message)) {
    window.location.reload()
  } else {
    Sentry.captureException(error.originalError || error.message || error.code || error)
  }
}