import { createContext, useContext } from 'react';

export const initialState = {
  user: {},
  configs: null,
  isAuthenticated: !!localStorage.getItem('sessionToken'),
  storeThreshold: {},
  currentReportTable: '',
  storeValue: null,
  isGetTransaction: false,
  segmentType: 0,
  loadingMembershipNo: false,
  gsts: [],
};

export function reducer(state, action) {
  switch (action.type) {
    case 'setUser':
      state.user = { ...action.payload };
      state.isAuthenticated = true;
      return { ...state };
    case 'login':
      state.loading = true;
      return { ...state };
    case 'loginSuccess':
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action?.payload?.data;
      localStorage.setItem(
        'sessionToken',
        action?.payload?.data?.jwToken || action?.payload?.accessToken,
      );
      localStorage.setItem(
        'user',
        JSON.stringify(action?.payload?.data || action?.payload?.account),
      );
      return state;
    case 'loginFail':
      state.loading = false;
      return state;
    case 'logout':
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('user');
      localStorage.removeItem('isPasswordExpired');
      localStorage.removeItem('isTmUser');
      return state;
    case 'setConfigs':
      state.configs = action.configs;
      return state;
    case 'setStoreThreshold':
      state.storeThreshold = action.threshold;
      return state;
    case 'setCurrentReportTable':
      state.currentReportTable = action.currentReportTable;
      return state;
    case 'setStoreId': {
      const cloneState = { ...state };
      cloneState.storeValue = action.payload;
      return cloneState;
    }
    case 'setIsGetTransaction': {
      const cloneState = { ...state };
      cloneState.isGetTransaction = action.payload;
      return cloneState;
    }
    case 'setSegmentType':
      state.segmentType = action.payload;
      return state;
    case 'setLoadingMembershipNo': {
      const cloneState = { ...state };
      cloneState.loadingMembershipNo = action.payload;
      return cloneState;
    }
    case 'setGSTs': {
      const cloneState = { ...state };
      cloneState.gsts = action.payload;
      return cloneState;
    }
    default:
      throw new Error();
  }
}

export const StoreContext = createContext({
  state: initialState,
});

export const useStore = () => {
  const { state, dispatch } = useContext(StoreContext);
  return [state, dispatch];
};
