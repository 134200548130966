import * as Sentry from "@sentry/browser";

function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1000)
        Sentry.addBreadcrumb({
          category: 'chunkLoadErrorFailed',
          message: JSON.stringify(error),
          level: Sentry.Severity.Info,
        })
      });
  });
}

export default componentLoader
