import { createGlobalStyle } from 'styled-components';
import { CLASS_UTILITY } from 'utils/css';

export default createGlobalStyle`
${CLASS_UTILITY}
.ant-design-pro {
  min-height: 100vh;
}
.ant-card-actions {
  li {
    margin:0px;
    button {
      width: 100%;
      height:50px;
    }
  }
}
.text-primary {}
.text-error {
  color:${({ theme }) => theme.color.red};
}
.bg {
  background: linear-gradient(180deg, rgb(129 189 65) 0%, rgb(129 189 65 / 50%) 100%);
}

.view-only {
  border: none;
  pointer-events: none;
  &:hover,&:focus {
    border: none;
    box-shadow:none;
  }
}
.btn-yellow {
  background:${({ theme }) => theme.color.yellow};
  border-color:${({ theme }) => theme.color.yellow};
}
.btn-green {
  background:${({ theme }) => theme.color.green};
  border-color:${({ theme }) => theme.color.green};
}
.btn-red {
  background:${({ theme }) => theme.color.red};
  border-color:${({ theme }) => theme.color.red};
}
.whitespace-pre-line {
  white-space: pre-line;
}
.self-center {
  align-self: center;
}
@media print {
  section > div:nth-child(1), aside, header, footer, .ant-anchor {
    display: none;
  }
}`;
