import { notification } from 'antd';
import { useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { errorHandler } from 'utils/errorHandler';

function ReactQueryProvider({ children }) {
  const queryClientRef = useRef();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
          onError: (error) => {
            errorHandler(error);

            if (error.code === 401) {
              const hasTMsRole = localStorage
                .getItem('userType')
                ?.includes('TMs');
              const clearStorageTimeout = setTimeout(() => {
                localStorage.clear();
                clearTimeout(clearStorageTimeout);
              }, 0);
              window.location = hasTMsRole ? '/tmlogin' : '/retaillogin';

              return;
            }
            notification.error({
              message: error?.message,
            });
          },
        },
        mutations: {
          onError: (error) => {
            errorHandler(error);
            notification.error({
              message: error?.message,
            });
          },
        },
      },
    });
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default ReactQueryProvider;
