import { Form, Input, Modal, notification, Select } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { changeStorePINApi } from 'api/auth';
import { getMachinesApi } from 'api/configs';
import { useIsRetailer } from 'hooks/useAuth';
import { encryptSha512Data } from 'utils/tools';
import { validateRegex } from 'utils/validateUtils';
import { useStore } from 'hooks/useStore';

function ChangeStorePINModal(props, ref) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [{ storeValue, user }] = useStore();
  const isRetailer = useIsRetailer();

  const [visible, setVisible] = useState(false);

  const { data: machines, refetch } = useQuery(
    ['machines'],
    async () => {
      const res = isRetailer
        ? await getMachinesApi({
            storeId: storeValue || user?.storeInfos?.[0]?.storeId,
          })
        : [];
      return res;
    },
    {
      enabled: !!isRetailer,
    },
  );

  useEffect(() => {
    refetch();
  }, [storeValue]);

  const { mutate, isLoading } = useMutation(
    (payload) => changeStorePINApi(payload),
    {
      onSuccess: (res) => {
        form.resetFields();
        setVisible(false);
        notification.info({
          message: res?.message || '',
        });
      },
    },
  );
  const onFinish = (values) => {
    const { oldPin, newPin, confirmPin } = values;
    mutate({
      ...values,
      oldPin: encryptSha512Data(oldPin),
      newPin: encryptSha512Data(newPin),
      confirmPin: encryptSha512Data(confirmPin),
      storeId: storeValue || user?.storeInfos?.[0]?.storeId,
    });
  };

  useImperativeHandle(ref, () => ({ setVisible }), []);

  return (
    <div>
      <Modal
        title={t('users.changeStorePIN')}
        visible={visible}
        onCancel={() => setVisible(false)}
        confirmLoading={isLoading}
        onOk={() => form.submit()}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item
            rules={[{ required: true }]}
            name="merchantTerminalId"
            required
            label={t('users.machineId')}
          >
            <Select allowClear className="w-100">
              {machines?.map(
                (value) =>
                  value && (
                    <Select.Option key={value.id} value={value.id}>
                      {value.machineId}
                    </Select.Option>
                  ),
              )}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              { required: true },
              {
                pattern: new RegExp(validateRegex.pin),
                message: t('error.pin'),
              },
            ]}
            name="oldPin"
            required
            label={t('users.oldPin')}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true },
              {
                pattern: new RegExp(validateRegex.pin),
                message: t('error.pin'),
              },
            ]}
            name="newPin"
            required
            label={t('users.newPin')}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            dependencies={['newPin']}
            required
            label={t('users.confirmPin')}
            hasFeedback
            name="confirmPin"
            rules={[
              {
                required: true,
                message: 'Please confirm your PIN!',
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPin') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two PIN that you entered do not match!'),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default forwardRef(ChangeStorePINModal);
