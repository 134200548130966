/* eslint-disable no-template-curly-in-string */
import BrandImage from '../assets/images/brand.png';

export const formConfig = {
  validateMessages: {
    required: 'Please enter ${label}!',
    whitespace: ' ${label} cannot be empty!',
    string: {
      min: '${label} must be minimum ${min} characters.',
      max: '${label} must be maximum ${max} characters.',
    },
    types: {
      number: '${label} must be number.',
    },
    pattern: {
      mismatch: '${label} is not valid!',
    },
  },
  requiredMark: true,
};

export const appConfig = {
  title: 'Loyalty Portal',
  logo: BrandImage,
};

export const settings = {
  colorWeak: false,
  title: appConfig.title,
  headerHeight: 60,
  fixedHeader: true,
  fixSiderbar: true,
  language: 'en-US',
};
