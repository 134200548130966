import { Select } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  max-width: 350px;
`;

const SelectStoreHeader = ({ hasRetailer, storeValue, user, dispatch }) => {
  const handleStoreForRetailerChange = (value) => {
    dispatch({ type: 'setStoreId', payload: value });
  };

  return (
    hasRetailer && (
      <SelectWrapper className="store-name-wrapper d-flex h-full">
        <Select
          className="store-name self-center w-100"
          value={storeValue || user?.storeInfos?.[0]?.storeId}
          onChange={handleStoreForRetailerChange}
        >
          {user?.storeInfos?.map((store) => (
            <Select.Option key={store?.storeId} value={store?.storeId}>
              {store?.storeName}
            </Select.Option>
          ))}
        </Select>
      </SelectWrapper>
    )
  );
};

SelectStoreHeader.propTypes = {
  hasRetailer: PropTypes.bool,
  storeValue: PropTypes.number,
  user: PropTypes.object,
  dispatch: PropTypes.func,
};

export default SelectStoreHeader;
