import { notification } from 'antd';
import {
  getCurrentUserApi,
  loginApi,
  loginSSOApi,
  logoutByUserIdApi,
} from 'api/auth';
import { getGSTsApi } from 'api/configs';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { StoreContext, useStore } from './useStore';

export const useAuth = () => {
  const [state, dispatch] = useStore();
  const queryClient = useQueryClient();

  const checkWarningChangePassword = (date) =>
    moment(date).diff(moment(), 'days');

  const { mutateAsync: logoutUserMutation } = useMutation(async (id) => {
    await logoutByUserIdApi(id);
  });

  const setUser = useCallback(
    (data) => {
      dispatch({
        type: 'setUser',
        payload: data,
      });
    },
    [dispatch],
  );

  const logout = async (url, userId) => {
    if (userId) {
      await logoutUserMutation(userId);
      dispatch({
        type: 'logout',
      });
      window.location = url;
    }
  };

  const loginMutation = useMutation((payload) => loginApi(payload), {
    onSuccess: (res) => {
      dispatch({
        type: 'loginSuccess',
        payload: res,
      });
      localStorage.setItem('isFirstLogin', res?.data?.firstTimeLoginFlag);
      if (
        checkWarningChangePassword(res?.data?.requireChangePasswordDate) <= 0
      ) {
        localStorage.setItem('isPasswordExpired', true);
      }
      if (
        checkWarningChangePassword(res?.data?.requireChangePasswordDate) <= 7 &&
        checkWarningChangePassword(res?.data?.requireChangePasswordDate) > 0
      ) {
        notification.warning({
          message: 'Warning password expires!',
          description:
            'You need to update your password before the password expires!',
        });
      }
    },
    onError: () => {
      dispatch({
        type: 'loginFail',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries('auth/login');
    },
  });

  const login = async (data) => {
    try {
      dispatch({
        type: 'login',
      });
      await loginMutation.mutateAsync(data);
    } catch (error) {
      //
    }
  };

  const loginSSOMutation = useMutation((payload) => loginSSOApi(payload), {
    onSuccess: (res) => {
      dispatch({
        type: 'loginSuccess',
        payload: res,
      });
    },
    onError: (e) => {
      dispatch({
        type: 'loginFail',
      });
      notification.error({
        message:
          e?.error_message ||
          'Email does not exist in the system. Please contact the administrator for assistance.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries('auth/loginSSO');
    },
  });

  const loginSSO = async (data) => {
    try {
      dispatch({
        type: 'login',
      });
      await loginSSOMutation.mutateAsync(data);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    state.isAuthenticated &&
      queryClient.fetchQuery('userdetail', async () => {
        const res = await getGSTsApi();
        dispatch({
          type: 'setGSTs',
          payload: res?.data,
        });
        getCurrentUserApi().then((data) => {
          setUser(data.data);
          if (isNil(state?.storeValue)) {
            dispatch({ type: 'setStoreId', payload: data?.data?.[0]?.storeId });
          }
          if (data) {
            localStorage.setItem('userType', data?.data?.userType);
          }
          return data.data;
        });
      });
  }, [state.isAuthenticated, dispatch, setUser]);

  return {
    user: state.user,
    login,
    loginSSO,
    logout,
    setUser,
    isAuthenticated: state.isAuthenticated,
    loading: state.loading,
    loginError: loginMutation.error,
    loginSSOError: loginSSOMutation.error,
  };
};

export const useHasRole = (roles) => {
  const { state } = useContext(StoreContext);
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    setHasRole(!!roles?.find((e) => e === state.user?.roles));
  }, [state.user]);

  return hasRole || !roles;
};

export const useIsRetailer = () => {
  const [{ user }] = useStore();

  return user?.userType === 'Retailer';
};
