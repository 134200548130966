import { LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import ChangePasswordModal from 'components/users/ChangePasswordModal';
import ChangeStorePINModal from 'components/users/ChangeStorePINModal';
import { useAuth, useIsRetailer } from 'hooks/useAuth';
import useWindowSize from 'hooks/useWindowSize';
import { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { PERMISSION } from 'utils/constant';

const RightContentHeader = () => {
  const { user, logout } = useAuth();
  const ref = useRef();
  const modalChangePinRef = useRef();

  const [width] = useWindowSize();
  const userType = localStorage.getItem('userType');
  const url = userType === 'TMs' ? '/tmlogin' : '/';

  const isRetailer = useIsRetailer();
  const isTMUser = useMemo(() => user?.userType === 'TMs', [user]);

  const hasChangePin = useMemo(
    () =>
      isRetailer &&
      !!user?.permissions?.find(
        (permission) =>
          permission?.functionName === PERMISSION?.CHANGE_STORE_PIN,
      )?.canAccess,
    [user],
  );

  const menu = (
    <Menu>
      {!isTMUser && (
        <Menu.Item
          onClick={() => ref.current.setVisible(true)}
          key="changePassword"
          icon={<LockOutlined />}
        >
          Change Password
        </Menu.Item>
      )}
      {hasChangePin && (
        <Menu.Item
          onClick={() => modalChangePinRef.current.setVisible(true)}
          key="changePin"
          icon={<LockOutlined />}
        >
          Change Store PIN
        </Menu.Item>
      )}
      <Menu.Item
        icon={<LogoutOutlined />}
        onClick={() => logout(url, user?.id)}
        key="logout"
        disabled={!user?.id}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <DropdownWrapper overlay={menu}>
        <div className="info-wrapper">
          {(!isRetailer || width >= 576) && (
            <div className="info">
              <span className="name">{user?.userName}</span>
            </div>
          )}
          <Avatar size="large" title={user?.userName} src={user?.avatar}>
            {user?.userName?.slice(0, 1)}
          </Avatar>
        </div>
      </DropdownWrapper>
      <ChangePasswordModal ref={ref} />
      <ChangeStorePINModal ref={modalChangePinRef} />
    </>
  );
};

const DropdownWrapper = styled(Dropdown)`
  &.info-wrapper {
    display: flex;
    align-items: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 10px;

    span {
      line-height: normal;
    }

    .name {
      font-weight: 600;
    }
  }
`;

export default RightContentHeader;
