import NotFoundPage from 'pages/404Page';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import loadable from 'utils/loadable';
import publicRoutes from './PublicRoutes';
import privateRoutes from './PrivateRoutes';

let intervalId = null;

const Routes = () => {
  const { logout, isAuthenticated } = useAuth();

  useEffect(() => {
    const ele = document.getElementById('ipl-progress-indicator');
    if (ele) {
      setTimeout(() => {
        // fade out
        ele.classList.add('available');
      }, 500);
      setTimeout(() => {
        // remove from DOM
        ele.outerHTML = '';
      }, 1500);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem('previousSessionTime', Date.now());
      intervalId = setInterval(() => {
        const previousSessionTime = localStorage.getItem('previousSessionTime');
        const userType = localStorage.getItem('userType');
        const url = userType === 'TMs' ? '/tmlogin' : '/';
        const currentTime = Date.now();

        if (
          Math.floor((currentTime - previousSessionTime) / 1000 / 60) === 14
        ) {
          clearInterval(intervalId);
          logout(url);
        }
      }, 60000);
    } else {
      localStorage.removeItem('previousSessionTime');
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isAuthenticated]);

  return (
    <div>
      <BrowserRouter>
        <Switch>
          {publicRoutes()}
          {privateRoutes()}
          <Route
            path="/400-error"
            component={loadable(import('pages/400Page'))}
            exact
            key="/400-error"
          />
          <Route
            path="/401-error"
            component={loadable(import('pages/401Page'))}
            exact
            key="/401-error"
          />
          <Route
            path="/403-error"
            component={loadable(import('pages/403Page'))}
            exact
            key="/403-error"
          />
          <Route
            path="/404-error"
            component={loadable(import('pages/404Page'))}
            exact
            key="/404-error"
          />
          <Route
            path="/500-error"
            component={loadable(import('pages/500Page'))}
            exact
            key="/500-error"
          />
          <NotFoundPage />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
