import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const sentryCredentials = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Non-Error promise rejection captured',
    /^Loading chunk*/,
  ],
};

Sentry.init(sentryCredentials);
