import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .ant-pro-sider {
    height: calc(100% - 20px) !important;
    margin: 10px;
    border-radius: 10px;
    @media (max-width: 768px) {
      height: 100% !important;
      margin: 0px;
      border-radius: 0px;
    }
    background: linear-gradient(180deg, rgb(129 189 65) 0%, rgb(129 189 65 / 50%) 100%);
    .ant-menu-item-selected {
      width: calc(100% - 10px) !important;
      margin-left: 5px !important;
      margin-right: 5px !important;
      padding: 0 11px !important;
      background-color: #00000020 !important;
      border-radius: 20px;
    }
    .ant-menu-sub {
      background: transparent !important;
    }
    .ant-menu-submenu-title {
      color: #000;
    }
    .ant-menu-submenu-title:hover {
      color: #666;
    }
    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
      background: #000!important;
    }
    .ant-menu-submenu {
      .ant-menu-item-selected {
        padding-left: 32px !important;
      }
    }
  }

  .ant-menu-dark .ant-menu-item-selected {
    & .anticon, & .anticon + span {
      color: #000;
    }
  }
  
  .ant-layout-header {
    background-color: transparent;
  }

  .ant-pro-global-header {
    /* background-color: rgba(255,255,255,0.5); */
    margin: 10px 20px;
    border-radius: 30px;
    box-shadow:0 1px 20px 10px rgb(0 21 41 / 8%);
  }

  .ant-page-header {
    padding: 16px 0px;
  }

  .ant-pro-sider-logo h1 {
    color: #000;
  }

  .ant-list {
    .ant-col {
      height: 100%;
      .ant-list-item {
        height: 100%;
      }
      .ant-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        .ant-card-body{
          flex:1;
        }
      }
    }
  }

  .ant-form {
    .ant-card {
      .ant-card-head {
        background: #82BE44;
      }
    }
  }

  .ant-tabs-card {
    .ant-tabs-tab-active {
      background-color: ${({ theme }) => theme.palette.primary} !important;
      .ant-tabs-tab-btn {
        color: white !important;
      }
    }
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-btn {
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
  }

  .ant-anchor-wrapper {
    overflow:visible;
  }

  .ant-anchor-ink {
    &::before {
      display:none;
    }
  }

  .ant-form-item-label {
    white-space: normal;
    padding-bottom: 4px;
  }

  .ant-input-number-handler-wrap {
    display:none;
  }
 
  
  .ant-menu .ant-menu-item > .ant-menu-title-content {
    & > a {
      color: #000;
    }
    & > a:hover {
      color: #666
    }
  }
`;
