import { Form, Input, Modal, notification } from 'antd';
import { changePasswordApi } from 'api/auth';
import { useStore } from 'hooks/useStore';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { encryptPassword } from 'utils/tools';
import { validateRegex } from 'utils/validateUtils';

function ChangePasswordModal(props, ref) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [{ user }] = useStore();
  const [visible, setVisible] = useState(false);
  const { mutate, isLoading } = useMutation(
    (payload) => changePasswordApi(payload),
    {
      onSuccess: (res) => {
        form.resetFields();
        setVisible(false);
        notification.info({
          message: res?.message || '',
        });
      },
    },
  );
  const onFinish = (values) => {
    const { currentPassword, newPassword, confirmPassword } = values;
    mutate({
      ...values,
      userId: user?.id,
      currentPassword: encryptPassword(currentPassword),
      newPassword: encryptPassword(newPassword),
      confirmPassword: encryptPassword(confirmPassword),
    });
  };

  useImperativeHandle(ref, () => ({ setVisible }), []);

  return (
    <div>
      <Modal
        title={t('users.changePassword')}
        visible={visible}
        onCancel={() => setVisible(false)}
        confirmLoading={isLoading}
        onOk={() => form.submit()}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item
            rules={[
              { required: true },
              {
                pattern: validateRegex.password,
                message: t('error.validatePassword'),
              },
            ]}
            name="currentPassword"
            required
            label={t('users.currentPassword')}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true },
              {
                pattern: validateRegex.password,
                message: t('error.validatePassword'),
              },
            ]}
            name="newPassword"
            required
            label={t('resetPassword.newPassword')}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            dependencies={['newPassword']}
            required
            label={t('users.confirmPassword')}
            hasFeedback
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                pattern: validateRegex.password,
                message: t('error.validatePassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default forwardRef(ChangePasswordModal);
