import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MenuSidebarItem = ({ path }, dom) => <Link to={path ?? '/'}>{dom}</Link>;

MenuSidebarItem.propTypes = {
  path: PropTypes.string,
};

export default MenuSidebarItem;
