import styled from 'styled-components';

export const LayoutStyles = styled.div`
  .store-name-wrapper {
    overflow: hidden;
  }
  .store-name {
    margin-bottom: 0 !important;
    margin-left: 10px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
`;
