const formatErrors = (err) =>
  `${err?.error_message || ''} ${
    err?.error_details?.map((e) => e.error_message || '').join('\n') || ''
  }`.trim();

async function customFetch(path, headerOptions) {
  try {
    const res = await fetch(path, headerOptions);
    if (res.status < 300) {
      const response = JSON.parse(await res.text());
      localStorage.setItem('previousSessionTime', Date.now());
      return response;
    }
    try {
      const response = await res.json();
      const error = {
        code: res.status,
        ...response,
      };
      throw error;
    } catch (e) {
      if (
        res.status === 401 &&
        !res?.url?.includes('/account/profile') &&
        !res?.url?.includes('/common/stores') &&
        !res?.url?.includes('/common/gst')
      ) {
        window.location = '/401-error';
        return false;
      }

      if (res.status === 426) {
        const error = {
          code: res.status,
          message:
            'We have had some significant upgrades for the app. Please click below to upgrade your app!',
        };
        throw error;
      } else {
        const error = {
          code: res.status,
          message:
            e.error || formatErrors(e)
              ? formatErrors(e) || e.error
              : 'Something wrong. Please try again.',
        };
        throw error;
      }
    }
  } catch (error) {
    if (!error?.code) {
      const hasTMsRole = localStorage.getItem('userType')?.includes('TMs');
      const clearStorageTimeout = setTimeout(() => {
        localStorage.clear();
        clearTimeout(clearStorageTimeout);
      }, 0);
      window.location = hasTMsRole ? '/tmlogin' : '/retaillogin';
    }

    if (error?.code === 403) {
      window.location = '/403-error';
    }

    throw error;
  }
}

export const timeoutPromise = (promise) =>
  new Promise((resolve) => {
    promise.then((res) => {
      resolve(res);
    });
  });

export default customFetch;

function requestWrapper(method) {
  const request = async (
    url,
    data = null,
    params = {},
    dataType,
    ignoreAuth = false,
  ) => {
    let convertUrl = `${process.env.REACT_APP_SERVER_URL}/v1/server${url}`;
    let convertParams = params;
    let convertData = data || {};

    if (method === 'GET') {
      // is it a GET?
      // GET doesn't have data
      convertParams = convertData;
      if (convertParams !== null) {
        convertUrl = `${convertUrl}${
          getQueryString(convertParams)
            ? `?${getQueryString(convertParams)}`
            : getQueryString(convertParams)
        }`;
      }
      convertData = null;
    } else if (convertData === Object(convertData) && dataType !== 'file') {
      convertData = JSON.stringify(convertData);
    }

    // default params for fetch = method + (Content-Type)
    const defaults = {
      method,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    };
    // check that req url is relative and request was sent to our domain

    const token = localStorage.getItem('sessionToken');
    if (token && !ignoreAuth) {
      defaults.headers.Authorization = `Bearer ${token}`;
    }

    if (method === 'POST' || method === 'PUT') {
      defaults.headers.Accept = 'application/json';
      defaults.headers['Content-Type'] = 'application/json';
    }

    if (dataType === 'file') delete defaults.headers['Content-Type'];

    if (convertData) {
      defaults.body = convertData;
    }

    const paramsObj = {
      ...defaults,
      headers: { ...params, ...defaults.headers },
    };
    return customFetch(convertUrl, paramsObj);
  };
  return request;
}

export function getQueryString(params) {
  const esc = (e) => e;
  return Object.keys(params)
    .filter((k) => params[k] || params[k] === false || params[k] === 0)
    .map((k) => {
      if (Array.isArray(params[k])) {
        return params[k]?.map((e) => `${esc(k)}=${esc(e)}`).join('&');
      }
      return `${esc(k)}=${esc(params[k])}`;
    })
    .join('&');
}

export const get = requestWrapper('GET');
export const post = requestWrapper('POST');
export const put = requestWrapper('PUT');
export const patch = requestWrapper('PATCH');
export const del = requestWrapper('DELETE');
