import ProLayout from '@ant-design/pro-layout';
import { Alert } from 'antd';
import { getConfigApi } from 'api/configs';
import { appConfig, settings } from 'configs/config';
import { useIsRetailer } from 'hooks/useAuth';
import { useRoutes } from 'hooks/useRoutes';
import { useStore } from 'hooks/useStore';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { queryStringFromUrl } from 'utils/tools';
import MenuHeader from './MenuHeader';
import MenuSidebarItem from './MenuSidebarItem';
import RightContentHeader from './RightContentHeader';
import SelectStoreHeader from './components/SelectStoreHeader';
import { LayoutStyles } from './styles';

const PrivateLayout = ({ children }) => {
  const { pathname } = useLocation();
  const routes = useRoutes();

  const [{ storeValue, user }, dispatch] = useStore();
  const hasRetailer = useIsRetailer();
  const history = useHistory();

  useEffect(() => {
    if (hasRetailer) {
      const baseURIs = history.location.pathname.split('/');
      const hasTransaction = baseURIs.includes('transactions');
      const filter = {
        ...queryStringFromUrl(history.location.search),
        ...(hasTransaction
          ? { 
              store_id: storeValue,
              transaction_from: undefined,
              transaction_to: undefined,  
            }
          : { store_name: storeValue }),
      };

      const queryStringFilter = queryString.stringify(
        history.location.pathname === '/' ? {} : filter,
      );

      dispatch({
        type: 'setStoreId',
        payload: storeValue || user?.storeInfos?.[0]?.storeId,
      });

      history.push(`${history.location.pathname}?${queryStringFilter}`);
    }
  }, [storeValue]);

  useQuery(
    'config',
    async () => {
      const configs = await getConfigApi();
      dispatch({ type: 'setConfigs', configs });
      return configs;
    },
    [],
  );

  return (
    <LayoutStyles className="h-screen">
      <ProLayout
        route={routes}
        logo={appConfig.logo}
        location={{
          pathname,
        }}
        menuHeaderRender={MenuHeader}
        menuItemRender={MenuSidebarItem}
        headerContentRender={() => (
          <SelectStoreHeader
            hasRetailer={hasRetailer}
            storeValue={storeValue}
            user={user}
            dispatch={dispatch}
          />
        )}
        rightContentRender={() => <RightContentHeader />}
        {...settings}
        language="en-US"
      >
        <Alert.ErrorBoundary>{children}</Alert.ErrorBoundary>
      </ProLayout>
    </LayoutStyles>
  );
};

export default PrivateLayout;
